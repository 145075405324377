import React from "react"
import Link from "gatsby-link"
// import CoffeeImg from '../../../static/img/coffee.png'

const HomeMediaPost = ({ posts }) => {
  return (
    <div className="section is-paddingless">
      {posts.map(({ node: post }) => (
        <div className="news_item is-size-5" key={post.id}>
          <h1 className="title is-4">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.meta_description}</p>
        </div>
      ))}
    </div>
  )
}

export default HomeMediaPost

import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import HeroWrapper from "../../layouts/HeroWrapper"
import HomeMediaPost from "../../components/HomeMediaPost"
import LogoExtended from "../../img/logo_extended.png"
import upArrow from "../../img/up-arrow.svg"
import downArrow from "../../img/down-arrow.svg"
import blueArrow from "../../img/up-arrow.1.svg"

import ReactModal from "react-modal"
import { Player, ControlBar } from "video-react"
import Setia from "../../img/setiawangsa.png"
import Mukim from "../../img/mukimhutan.png"
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll"

function getParent() {
  return document.querySelector("#___gatsby")
}

class NewHomeComponent extends Component {
  render() {
    const meta_title = this.props.meta_title
    const meta_description = this.props.meta_description
    const posts = this.props.posts

    return (
      <HeroWrapper navbarClassName="landing-page">
        <Helmet>
          <title>{meta_title}</title>
          <meta name="description" content={meta_description} />
        </Helmet>

        <section className="hero is-fullheight" id="home_page-hero">
          <Element name="FirstContainer" className="element">
            <div className="hero-body" style={{ alignItems: `initial` }}>
              <div className="banner">
                <Link
                  activeClass="active"
                  to="SecondContainer"
                  spy={true}
                  smooth={true}
                  duration={250}
                >
                  <img className="image" src={LogoExtended} alt="" />
                </Link>
              </div>
              <div className="container-fluid">
                <div className="stats_container has-text-right is-hidden-touch">
                  <div className="stats_item">
                    <h2 className="stats_item-number is-size-1 has-text-weight-bold">
                      1
                    </h2>
                    <h5 className="stats_item-heading is-size-6 is-uppercase">
                      Floating Storage Offloading Unit (FSO)
                    </h5>
                  </div>
                  <div className="stats_item">
                    <h2 className="stats_item-number is-size-1 has-text-weight-bold">
                      8
                    </h2>
                    <h5 className="stats_item-heading is-size-6 is-uppercase">
                      Offshore Support Vessels
                    </h5>
                  </div>
                  <div className="stats_item">
                    <h2 className="stats_item-number is-size-1 has-text-weight-bold">
                      2
                    </h2>
                    <h5 className="stats_item-heading is-size-6 is-uppercase">
                      Oil Tankers
                    </h5>
                  </div>
                  <div className="stats_item">
                    <h2 className="stats_item-number is-size-1 has-text-weight-bold">
                      16
                    </h2>
                    <h5 className="stats_item-heading is-size-6 is-uppercase">
                      Port Operation Tugboats
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </Element>
          <div>
            <a
              className="button is-light is-medium"
              id="home_page-video_button"
              href="/"
            >
              Replay Intro Video
            </a>
          </div>
        </section>
        <section className="hero" id="home_page-vision">
          <Element name="SecondContainer" className="element">
            <div className="hero-body ">
              <div className="columns is-vcentered is-multiline is-mobile">
                <div
                  className="column is-2-desktop is-4-mobile"
                  id="vision-border-line"
                >
                  <h4
                    className="title has-text-weight-bold has-text-white is-uppercase"
                    id="vision-title"
                  >
                    Vision
                  </h4>
                </div>
                <div
                  className="column is-4-desktop is-7-mobile"
                  id="vision-desc"
                >
                  <h5 className="subtitle is-6 has-text-white">
                    To be the locally preferred service
                    <br />
                    provider of Marine Services with
                    <br /> a Global Vision
                  </h5>
                </div>

                <div
                  className="column is-3-desktop is-7-mobile"
                  id="mission-desc"
                >
                  <h5 className="subtitle is-6 has-text-white has-text-right">
                    A local shipping company focuses and exemplifies attributes
                    of:
                    <br />• Safety • Quality • Environment 
                    <br />• Security Culture
                  </h5>
                </div>
                <div
                  className="column is-2-desktop  is-4-mobile"
                  id="mission-border-line"
                >
                  <h4
                    className="title has-text-weight-bold has-text-white is-uppercase"
                    id="mission-title"
                  >
                    Mission
                  </h4>
                </div>
              </div>
            </div>
          </Element>
        </section>
        <section className="hero" id="home_page-intro">
          <div className="hero-body">
            <Link
              activeClass="active"
              to="FirstContainer"
              spy={true}
              smooth={true}
              duration={250}
            >
              <div className="intro_up_arrow">
                <img src={upArrow} alt="" />
              </div>
            </Link>
            <Link
              activeClass="active"
              to="ThirdContainer"
              spy={true}
              smooth={true}
              duration={250}
            >
              <div className="intro_down_arrow">
                <img src={downArrow} alt="" />
              </div>
            </Link>
            <div className="container">
              <div className="columns is-mobile is-multiline">
                <div className="column is-12-mobile">
                  <h3
                    className="subtitle is-1 is-uppercase
                    has-text-white has-text-centered is-mt-desktop-5"
                    id="who-are-we-header"
                  >
                    Who we are
                  </h3>
                </div>
                <div className="column">
                  <div className="content">
                    <p style={{ textAlign: `justify` }}>
                      E.A. Technique (M) Berhad is a local marine company that owns and operates marine vessels where our business is focused on marine transportation and offshore storage of oil & gas (“O&G”), and provision of port marine services. 
                      <br />
                      <br />
                      The Company is involved in the charter of various types of tankers for the transportation and offshore storage of oil & gas, charter of marine tug vessels for the provision of port marine services and charter of Offshore Support Vessels (“OSV”) in the form of fast crew boats to transport personnel/light cargoes between shore and platform, platform and platform and other offshore facilities. 
                      <br />
                      <br />
                      As at 31 March 2023, the Company owns a total fleet of twenty-nine (29) marine vessels in our portfolio, which comprises two (2) product tankers, two (2) Floating Storage & Offloading Unit (“FSU/FSO”), nine (9) OSVs and sixteen (16) marine vessels. 
                      <br />
                      <br />
                      To strengthen our position in shipping and maritime industry, we also ventured into shipbuilding, ship repair and minor fabrication since 2007 through our subsidiary, Johor Shipyard and Engineering Sdn Bhd (“JSE”), which operating on a 20 acres land in Hutan Melintang, Perak. JSE has been awarded as “Outstanding Ship Builder” by Ministry of Transport in 2018 and has also been shortlisted as the top ten (10) best shipyards in Malaysia for the Petronas Offshore Safina Project.
                      <br />
                      <br />
                      Libra Perfex Precision Sdn Bhd as our wholly owned subsidiary is providing tugboats for the operations of Petronas Floating LNG (L) Ltd (“PFLNG”).
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section" id="home_page-misc">
          <Element name="ThirdContainer" className="element">
            <Link
              activeClass="active"
              to="SecondContainer"
              spy={true}
              smooth={true}
              duration={250}
            >
              <div className="misc_blue_arrow">
                <img src={blueArrow} alt="" />
              </div>
            </Link>
            <div className="container">
              <div className="columns">
                <div className="column news is-6 is-px-5 is-pt-5">
                  <h4 className="title has-text-weight-bold is-uppercase">
                    Latest News
                  </h4>
                  <div className="is-news" id="news-scroll">
                    <HomeMediaPost posts={posts.slice(0, 5)} />
                  </div>
                  <div
                    className="button is-pulled-right"
                    style={{ backgroundColor: `#0A4DA4`, color: `white` }}
                  >
                    <a
                      href="/media"
                      style={{ backgroundColor: `#0A4DA4`, color: `white` }}
                    >
                      Read More
                    </a>
                  </div>
                </div>
                <div className="column" />
                <div className="column contactus is-6">
                  <div>
                    <h4 className="title has-text-weight-bold is-uppercase is-pl-5-mobile">
                      Contact Us
                    </h4>
                    <div className="content">
                      <div className="columns">
                        <div className="column">
                          <h1 className="title is-6" id="event-title">
                            E.A. TECHNIQUE (M) BERHAD
                          </h1>
                          <p className="is-size-7">
                            Setiawangsa Business Suites
                            <br />
                            Unit C-3A-3A, No.2 Jalan Setiawangsa 11
                            <br />
                            Taman Setiawangsa 54200 <br />
                            Kuala Lumpur Malaysia
                          </p>
                          <p className="is-size-7">
                            T +603 4252 5422 (Hunting)
                            <br />
                            F +603 4252 2163 (Account/Admin Dept)
                            <br /> &nbsp; &nbsp; +603 4251 2985 (Operation)
                            <br />E eat@eatechnique.com.my
                            <br />
                            <br />
                            <br />
                            <br />
                          </p>
                        </div>

                        <div className="column setia-img is-ml-5-mobile">
                          <a
                            target="_blank"
                            href="https://www.google.com/maps/place/E.A+Technique+(M)+Berhad/@3.1796294,101.7416504,17z/data=!3m1!4b1!4m5!3m4!1s0x31cc379ae1352329:0x3259c65e859553da!8m2!3d3.1796294!4d101.7438391"
                          >
                            <figure className="image is-4by3">
                              <img
                                src={Setia}
                                style={{ border: "4px solid #cccccc" }}
                              />
                            </figure>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="content">
                      <div className="columns">
                        <div className="column">
                          <h1 className="title is-6" id="event-title">
                            JOHOR SHIPYARD AND ENGINEERING SDN BHD
                          </h1>
                          <p className="is-size-7">
                            Johor Shipyard and Engineering Sdn Bhd
                            <br />
                            Lot PT8436-A
                            <br />
                            Mukim Hutan Melintang
                            <br />
                            36400 Daerah Hilir Perak
                            <br />
                            Perak Darul Ridzuan
                            <br />
                            <br />T +605 641 2514
                            <br /> F +605 641 3679
                            <br />E jse@johorshipyard.com.my
                          </p>
                        </div>

                        <div className="column">
                          <a
                            target="_blank"
                            href="https://www.google.com/maps/place/Johor+Shipyard+and+Engineering+Sdn.+Bhd./@3.8632336,100.894885,17z/data=!3m1!4b1!4m5!3m4!1s0x31cb436466b127e1:0xd0a5e028f543c025!8m2!3d3.8632283!4d100.897079"
                          >
                            <figure className="image is-4by3">
                              <img
                                src={Mukim}
                                style={{ border: "4px solid #cccccc" }}
                              />
                              />
                            </figure>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Element>
        </section>
      </HeroWrapper>
    )
  }
}

NewHomeComponent.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  vision: PropTypes.string,
  mission: PropTypes.string,
}

const NewHomePage = ({ data }) => {
  const { frontmatter } = data.index.edges[0].node
  const { edges: posts } = data.posts

  return (
    <NewHomeComponent
      title={frontmatter.title}
      meta_title={frontmatter.meta_title}
      meta_description={frontmatter.meta_description}
      heading={frontmatter.heading}
      description={frontmatter.description}
      vision={frontmatter.vision}
      mission={frontmatter.mission}
      posts={posts}
    />
  )
}

export default NewHomePage

export const pageQuery = graphql`
  query NewHomeQuery {
    index: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            meta_title
            meta_description
            heading
            description
            vision
            mission
            offerings {
              blurbs {
                image
                text
              }
            }
            testimonials {
              author
              quote
            }
          }
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "media-page" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            cover
            templateKey
            date(formatString: "YYYY, MM DD")
            year
            meta_description
          }
        }
      }
    }
  }
`

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import "../css/styles.sass"
import config from "../../meta/config"

const HeroWrapper = (props) => {
  return (
    <div>
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta name="description" content={config.siteDescription} />
      </Helmet>
      <NavBar
        navbarClassName={props.navbarClassName}
        hideMenu={props.hideMenu}
      />
      <div>{props.children}</div>
      <Footer />
    </div>
  )
}

HeroWrapper.propTypes = {
  children: PropTypes.any,
  navbarClassName: PropTypes.string,
  hideMenu: PropTypes.bool,
}

export default HeroWrapper
